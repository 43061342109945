<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="popup-header left">
      <div class="popup-title">
        <img src="~@/assets/images/icon/fault_icon.svg" alt="">
        故障排查提示
      </div>
      <div class="close-btn" @click="onClose">关闭</div>
    </div>
    <div class="fault-popup-body">
      <div class="fault-tip">为了方便快速解决您的故障问题，以下为您推荐故障排除建议。</div>
      <div class="fault-detail-box">
        <template v-if="content.list">
          <div class="fault-detail-item" v-for="(item,index) in content.list" :key="index">
            <p v-html="item.title"></p>
          </div>
        </template>
      </div>
      <div class="fault-video-box" v-if="content.video_code" >
        <div class="fault-video" v-html="content.video_code">
        </div>
        <div class="video-tip">故障视频讲解</div>
      </div>
      <div class="fault-footer flex-center">
        <div class="fault-btn flex-center" :class="{'active': type == 2}"  @click="changeStatus(2)">
          <img src="~@/assets/images/icon/icon_nozan.svg" class="before" alt="">
          <img src="~@/assets/images/icon/icon_nozan_cur.svg" class="after" alt="">
          <span>未解决</span>
        </div>
        <div class="fault-btn flex-center" :class="{'active': type == 1}" @click="changeStatus(1)">
          <img src="~@/assets/images/icon/icon_zan.svg" class="before" alt="">
          <img src="~@/assets/images/icon/icon_zan_cur.svg" class="after" alt="">
          <span>已解决</span>
        </div>
      </div>
    </div>
    
  </van-popup>
</template>

<script>
  import { Popup } from 'vant'
  import { getFaultStatus, faultRate } from '@/api/service'

  export default {
    components: {
      [Popup.name]: Popup
    },
    props: {
      fault: {
      },
      content: {
      },
      visible: {
        type: Boolean
      }
    },
    watch: {
      fault(val) {
        if (val.id) {
          getFaultStatus({id: val.id}).then(res => {
            this.type = res.data.type
          })
        }
      }
    },
    data() {
      return {
        type: 0
      }
    },
    methods: {
      onClose() {
        this.$emit('close', true)
      },
      changeStatus(type) {
        const params = {
          type,
          id: this.fault.id
        }
        faultRate(params).then(() => {
          this.type = type
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
</style>