<template>
  <van-popup v-model="visible" round position="bottom" @close="onClose">
    <div class="popup-header">
      <div class="popup-title">
        选择故障类型
      </div>
    </div>
    <div class="fault-tips">
      <div class="info-tips">
        <van-icon name="info-o" color="#FF954B" size="3.2vw"/>
        <span>温馨提示：如选择故障其他（请填写相关故障描述）</span>
      </div>
    </div>
    <div class="fault-type-box">
      <div class="fault-item flex-center" 
        v-for="(item,index) in list" :key="index"
        @click="checkId = index"
        :class="{'active': checkId === index}">
        {{ item.title }}
      </div>
    </div>
    <div class="popup-footer">
      <van-button class="popup-btn mini" type="default" @click="onClose">
        返回
      </van-button>
      <van-button class="popup-btn small" type="info" @click="onSubmit" :loading="loading">
        确认
      </van-button>
    </div>
  </van-popup>
</template>

<script>
  import { Popup, Button, Toast, Icon } from 'vant'
  import { getFaultList, getFaultInfo } from '@/api/service'

  export default {
    components:{
      [Popup.name]: Popup,
      [Button.name]: Button,
      [Icon.name]: Icon,
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      product: {}
    },
    data() {
      return {
        list: [],
        checkId: -1,
        loading: false,
      }
    },
    watch: {
      product(val) {
        if (val && val.category) {
          this.loadData(val.category)
        }
      }
    },
    methods: {
      loadData(category) {
        getFaultList({category: category}).then(res => {
          this.list = res.data.list
        })
      },
      onClose() {
        this.$emit('close', true)
      },
      onSubmit() {
        if (this.checkId === -1) {
          Toast('请选择故障类型')
          return
        }
        const fault = this.list[this.checkId]
        if (fault.title === '其他') {
          this.$emit('other', fault)
        } else {
          this.loading = true
          getFaultInfo({id: fault.id}).then(res => {
            const result = {
              fault,
              content: res.data
            }
            this.$emit('submit', result)
            this.loading = false
          })
        }
        
      }
    },
  }
</script>

<style lang="less" scoped>
  @import '../style.less';
  .fault-tips {
    margin: 0 6.6667vw;
  }
  .fault-type-box {
    margin: 4.6667vw 4.6667vw 4vw;
    display: flex;
    flex-wrap: wrap;
    .fault-item {
      width: calc(33.33% - 4vw);
      margin: 0 2vw 4vw;
      border-radius: 0.5333vw;
      background: #F4F4F4;
      height: 9.3333vw;
      font-size: 3.4667vw;
      color: #1C1F20;
      &.active {
        color: #FFFFFF;
        background: #186AFF
      }
    }
  }
</style>